export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  const currentPosition = getSavedScrollPosition(location)
  if (!location.hash) {
    setTimeout(() => {
      window.scrollTo({
        top: currentPosition[0] || 0,
        left: currentPosition[1] || 0,
        behavior: 'instant',
      })
    }, 0)
    return false
  }
}
